<template>
  <div class="auth-container">
    <div class="auth-hero">
      <img :src="heroImageSrc" class="auth-hero-image" />
    </div>
    <div class="auth-content">
      <div class="auth-logo">
        <img :src="logoSrc" class="logo" />
        <span class="logo-badge">
          <small>{{ $t('for') }}&nbsp;</small>
          <strong>{{ $t('business') }}</strong>
        </span>
      </div>
      <p>{{ helpText }}</p>
      <validation-observer ref="resetPasswordForm">
        <b-form @submit.prevent="validationForm">
          <b-form-group :label="$t('Email')" label-for="forgot-password-email">
            <validation-provider #default="{ errors }" :name="$t('Email')" rules="required|email">
              <b-form-input
                id="forgot-password-email"
                v-model="userEmail"
                :state="errors.length > 0 ? false : null"
                name="forgot-password-email"
                placeholder="john@example.com"
                :readOnly="$route.query.email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="passwordLabel" label-for="reset-password-new">
            <validation-provider
              #default="{ errors }"
              :name="$t('Password')"
              vid="Password"
              rules="required|password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="reset-password-new"
                  v-model="password"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  name="reset-password-new"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="reset-password-confirm" :label="$t('Confirm Password')">
            <validation-provider
              #default="{ errors }"
              :name="$t('Confirm Password')"
              rules="required|confirmed:Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="reset-password-confirm"
                  v-model="confirmPassword"
                  :type="confirmPasswordFieldType"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false : null"
                  name="reset-password-confirm"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="confirmPasswordToggleIcon"
                    @click="toggleConfirmPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button block type="submit" variant="primary" :disabled="isLoading">
            {{ buttonText }}
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BImg,
} from 'bootstrap-vue'

import heroImageSrc from '@/assets/images/provider/login-background.jpg'
import logoSrc from '@/assets/images/logo/toddl.co_logo white.svg'
import { password, required } from '@/libs/validations'
import AuthService from '@/services/AuthService'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      userEmail: this.$route.query.email ?? '',
      password: '',
      passwordFieldType: 'password',
      confirmPassword: '',
      confirmPasswordFieldType: 'password',
      required,
      AuthService: new AuthService(),
      heroImageSrc,
      logoSrc,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    helpText() {
      return this.$route.query.claim === '1'
        ? this.$t('Choose the password you will use to access the toddl dashboard')
        : this.$t('Your new password must be different from previously used passwords')
    },
    buttonText() {
      return this.$route.query.claim === '1' ? this.$t('Save') : this.$t('Set New Password')
    },
    passwordLabel() {
      return this.$route.query.claim === '1' ? this.$t('Password') : this.$t('New Password')
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.resetPasswordForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.AuthService.resetPassword({
            token: this.$route.params.token,
            email: this.userEmail,
            password: this.password,
            password_confirmation: this.confirmPassword,
          })
            .then(() => {
              this.isLoading = false
              this.$router.replace({ path: '/login' })
            })
            .catch(err => {
              console.error(err)
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style scoped>
.auth-container {
  display: grid;
  gap: 0;
  grid-template-columns: minmax(0, 0%) minmax(0, 1fr);
  height: 100vh;
  background-color: #fff;
}

.auth-hero {
  position: relative;
  height: 100%;
}

.auth-hero-image {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.auth-content {
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #0e1a1a;
}

.auth-logo {
  display: flex;
  flex-direction: row;
  height: fit-content;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 32px;
}
.logo {
  width: 100%;
}

.logo-badge {
  font-size: 26px;
  display: inline-block;
  color: #4b64a5;
  margin-bottom: 8px;
}

.logo-badge strong {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .auth-container {
    grid-template-columns: minmax(0, 66%) minmax(0, 1fr);
  }
}
</style>
